import {
  buildSectionPageTarget,
  buildCategoryOrTagePageTargets,
  buildArticlePageTargets,
} from './helpers';
import { isShowingError } from 'global-utils';

export class AdPublisher {
  targets = {};

  constructor(state, pageCtx) {
    this.state = state;
    this.pageCtx = pageCtx;
    this.pubCtx = state.publication;
    this.prefix = this.buildPrefix();
    this.targets = {
      ads_env: state.adsEnv,
      user_login: `${document.cookie.includes('oneid_info')}`,
    };
  }

  buildPrefix() {
    return (this.pubCtx.adPrefixCode || this.pubCtx.code).toLowerCase();
  }

  buildPrefixedTargets() {
    const pageType = this.pageCtx.type;
    const sectionTarget = buildSectionPageTarget(pageType, this.state);
    let pageTypeTarget = {};
    switch (pageType) {
    case 'categories':
    case 'tags':
      pageTypeTarget = buildCategoryOrTagePageTargets(this.state);
      break;
    case 'articlepage':
    case 'articlePreviewPage':
      pageTypeTarget = buildArticlePageTargets(this.state);
      break;
    }
    const prefixableTargets = { ...sectionTarget, ...pageTypeTarget };
    return Object.entries(prefixableTargets).reduce((cum, [key, val]) => {
      cum[`${this.prefix}_${key}`] = val;
      return cum;
    }, {});
  }

  publish() {
    const googletag = window.googletag = window.googletag || {};
    if (googletag.adPublised || isShowingError(this.state.errorCtx)) {
      return false;
    }

    googletag.cmd = googletag.cmd || [];
    googletag.cmd.push(() => {
      googletag.pubads().disableInitialLoad();
      this.setTargets();
      googletag.pubads().collapseEmptyDivs();
      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
    });
    googletag.adPublised = true;
  }

  setTargets() {
    const targets = { ...this.targets, ...this.buildPrefixedTargets() };
    Object.entries(targets).forEach(([key, val]) => {
      console.debug(`Google ads target: ${key}: ${val}`);
      googletag.pubads().setTargeting(key, val);
    });
  };
}

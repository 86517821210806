import { _isNil } from 'global-utils';

function buildTagScheme(tag, domain) {
  return `tag:${tag}@${domain}`;
}

export function getAdDomain(pubCtx) {
  return pubCtx.adDomain || pubCtx.title.replace(/(www.|stage.|test.|dev.)/g, '');
}

const adSections = {
  frontpage: 'ece_frontpage',
  epaper: 'editions',
  articlepage: 'articlepage',
  articlePreviewPage: 'articlepage',
  articlePaywallPage: 'articlepage',
  tags: 'tagpage',
  categories: 'categoriespage',
  error404: 'errorPage'
};

export function buildSectionPageTarget(pageType, state) {
  return {
    section: adSections[pageType] === 'categoriespage'
      ? state.pageTitle.toLowerCase()
      : adSections[pageType] || pageType,
  };
}

export function buildCategoryOrTagePageTargets(state) {
  return {
    tag_term: state.pageTitle.toLowerCase(),
    tag_scheme: buildTagScheme(
      state.pageTitle.toLowerCase(),
      getAdDomain(state.publication)
    ),
  };
}

export function buildArticlePageTargets(state) {
  const mainCategory = state.article.mainCategory.id.toLowerCase();
  const targets = { article_section: mainCategory };

  if (!_isNil(state.article.tags)) {
    const terms = [];
    const schemes = [];
    const tags = state.article.tags.slice(0, 5);
    for (const tag of tags) {
      terms.push(tag.label);
      schemes.push(
        buildTagScheme(tag.type.toLowerCase(), getAdDomain(state.publication)));
    }
    targets.tag_scheme = schemes;
    targets.tag_term = terms;
  }
  return targets;
}
